<template>
  <main class="page">
    <section class="page__content">
      <h1>Foto album</h1>
      <div class="container">
        <div
          class="image"
          v-for="(img, index) in images"
          :key="`img--${index}`"
          @click="openModal(index)"
        >
          <img :src="img" :alt="`sfeerfoto ${index + 1} V-Tax`" />
        </div>
      </div>
      <div v-if="showModal" class="modal">
        <div class="modal-content">
          <span class="close" @click="closeModal">×</span>
          <img
            :src="images[activeImg]"
            :alt="`sfeerfoto ${activeImg} V-Tax`"
            class="modal-image"
          />
          <button @click="onPrevClicked">
            vorige
          </button>
          <button @click="onNextClicked">
            volgende
          </button>
        </div>
      </div>
    </section>
  </main>
</template>
<script>
export default {
  name: "Album",
  data() {
    return {
      images: Array.from({ length: 14 }, (_, i) => `/album/vt${i + 1}.jpg`),
      activeImg: null,
      showModal: false,
    };
  },
  methods: {
    onPrevClicked() {
      if (this.activeImg === 0) {
        this.activeImg = this.images.length - 1;
        return;
      }
      this.activeImg -= 1;
    },
    onNextClicked() {
      if (this.activeImg === this.images.length - 1) {
        this.activeImg = 0;
        return;
      }
      this.activeImg += 1;
    },
    openModal(index) {
      this.activeImg = index;
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
    },
  },
};
</script>
<style scoped>
.container {
  margin: 2rem auto;
  max-width: 1200px;
  display: grid;
  padding: 0.5rem;
  background: #eeeeee;
  grid-template-columns: repeat(auto-fit, minmax(min(320px, 100%), 1fr));
  justify-content: center;
}
.image {
  width: 100%;
  height: 320px;
  cursor: pointer;
  transition: all 0.5s ease;
  box-shadow: none;
  transform: scale(0.95);
}
.image:hover {
  z-index: 2;
  transform:scale(1);
}
.image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
}
.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 1000;
}
.modal-content {
  position: relative;
  width: 90%;
  height: 90%;
}
.modal-image {
    object-fit: contain;
  width: 100%;
  height: 100%;
}
.close {
  position: absolute;
  top: 0px;
  right: 20px;
  color: #e5222f;
  font-size: 48px;
  cursor: pointer;
  transition: all 0.5s ease;
}
.close:hover {
  color: var(--color-primary);
}
button {
  appearance: none;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  padding: 10px;
  cursor: pointer;
  background: #e5222f;
  color: white;
  transition: all 0.5s ease;
  text-transform: uppercase;
  font-weight: bold;
}
button:hover {
  background: #F2635C;
}
button:nth-of-type(1) {
  left: 10px;
}
button:nth-of-type(2) {
  right: 10px;
}
</style>
